(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/player-wager-list-subscription-slice.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/player-wager-list-subscription-slice.js');
"use strict";


const {
  createSlice,
  createEntityAdapter,
  combineReducers,
  createReducer,
  createAction
} = RTK;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  WagerStatus
} = svs.components.sport.tipsenShared;
const {
  productIds
} = svs.utils.products;
const {
  isTopptipset,
  isTopptipsetStryk,
  isTopptipsetExtra
} = svs.utils.products.helpers;
const wagerListSubscriptionAdapter = createEntityAdapter({
  selectId: _ref => {
    let {
      wagerId
    } = _ref;
    return String(wagerId);
  }
});
const toggleSubscriptionOption = createAction('tipsen/toggleSubscriptionOption');
const makePlayerWagerListSubscriptionReducer = preloadedState => createReducer(preloadedState, builder => {
  builder.addCase(toggleSubscriptionOption, (state, action) => {
    const {
      productId,
      status
    } = action.payload;
    const correctsToggleObj = {};
    const statusToggle = {
      active: false,
      finished: false
    };
    const currentProductIds = [];
    if (isTopptipset(productId) || isTopptipsetExtra(productId) || isTopptipsetStryk(productId)) {
      currentProductIds.push(productIds.T8, productIds.T8EXTRA, productIds.T8STRYK);
    } else {
      currentProductIds.push(productId);
    }
    currentProductIds.forEach(productId => {
      if (state.correctsToggle) {
        if (Object.keys(state.correctsToggle).includes(productId.toString())) {
          var _state$correctsToggle, _state$correctsToggle2;
          state.correctsToggle[productId][status] = !state.correctsToggle[productId][status];
          if (!((_state$correctsToggle = state.correctsToggle[productId]) !== null && _state$correctsToggle !== void 0 && _state$correctsToggle[WagerStatus.Active]) && !((_state$correctsToggle2 = state.correctsToggle[productId]) !== null && _state$correctsToggle2 !== void 0 && _state$correctsToggle2[WagerStatus.Finished])) {
            delete state.correctsToggle[productId];
          }
        } else {
          state.correctsToggle[productId] = statusToggle;
          if (status === WagerStatus.Active) {
            state.correctsToggle[productId].active = true;
          } else {
            state.correctsToggle[productId].finished = true;
          }
        }
      } else {
        correctsToggleObj[productId] = statusToggle;
        correctsToggleObj[productId][status] = true;
        if (status === WagerStatus.Active) {
          correctsToggleObj[productId] = statusToggle;
          correctsToggleObj[productId].active = true;
        } else {
          correctsToggleObj[productId].finished = true;
        }
        state.correctsToggle = correctsToggleObj;
      }
    });
  });
});
const playerWagerListSubscriptionSlice = createSlice({
  name: 'WagerListSubscription',
  initialState: wagerListSubscriptionAdapter.getInitialState(),
  reducers: {
    addForecastSubscriptions: wagerListSubscriptionAdapter.addMany,
    toggleSubscriptionForWager: (state, _ref2) => {
      let {
        payload: {
          wagerId
        }
      } = _ref2;
      state.entities[wagerId].isSubscribed = !state.entities[wagerId].isSubscribed;
    },
    deleteForecastSubscriptions: (state, _ref3) => {
      let {
        payload: wagerIds
      } = _ref3;
      wagerListSubscriptionAdapter.removeMany(state, wagerIds);
    }
  },
  extraReducers: builder => {
    builder.addMatcher(tipsenApi.endpoints.getPlayerWagers.matchFulfilled, (state, _ref4) => {
      let {
        payload,
        meta
      } = _ref4;
      const {
        status,
        pagingSortKey
      } = meta.arg.originalArgs;
      const {
        isProductToggled
      } = meta.enhanchedData;
      if (status === 'active') {
        const wagersData = [];
        const wagerIds = [];
        const noLongerActiveIds = [];
        let subscribedValue = false;
        if (!state.length && isProductToggled) {
          subscribedValue = true;
        }
        payload.wagers.forEach(wager => {
          wagerIds.push(wager.wagerId);
          wagersData.push({
            wagerId: wager.wagerId,
            drawNumber: wager.currentDrawNumber,
            productId: wager.productId,
            isSubscribed: subscribedValue
          });
        });
        const hasWagers = Boolean(payload.wagers.length);
        if (!pagingSortKey && state.ids.length || !hasWagers && state.ids.length) {
          state.ids.forEach(wagerId => {
            if (hasWagers && !wagerIds.includes(wagerId) || !hasWagers) {
              noLongerActiveIds.push(wagerId);
            }
          });
        }
        if (noLongerActiveIds.length) {
          wagerListSubscriptionAdapter.removeMany(state, noLongerActiveIds);
        }
        if (wagersData.length) {
          wagerListSubscriptionAdapter.addMany(state, wagersData);
        }
      }
    }).addMatcher(RTK.isAnyOf(toggleSubscriptionOption), (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        isToggleOn,
        status
      } = payload;
      if (status === 'active') {
        Object.values(state.entities).forEach(wager => {
          wager.isSubscribed = isToggleOn;
        });
      }
    }).addMatcher(tipsenApi.endpoints.invalidatePlayerWagerTag.matchFulfilled, (state, _ref6) => {
      let {
        meta
      } = _ref6;
      const {
        wagerId
      } = meta.arg.originalArgs;
      wagerListSubscriptionAdapter.removeOne(state, wagerId);
    });
  }
});
const selectToggle = state => state.PlayerWager.WagerListSettings.WagerListSubscriptionSettings.correctsToggle;
const makePlayerWagerListReducer = preloadedState => combineReducers({
  WagerListForecastSubscriptions: playerWagerListSubscriptionSlice.reducer,
  WagerListSubscriptionSettings: makePlayerWagerListSubscriptionReducer(preloadedState)
});
setGlobal('svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice', {
  makePlayerWagerListReducer,
  actions: {
    wagerListForecastSubscriptions: playerWagerListSubscriptionSlice.actions,
    toggleSubscriptionOption
  },
  selectors: {
    wagerListForecastSubscriptions: wagerListSubscriptionAdapter.getSelectors(state => state.PlayerWager.WagerListSettings.WagerListForecastSubscriptions),
    wagerListSubscriptionSettings: {
      selectToggle
    }
  }
});

 })(window);